import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path:'/',//nextop官网
    name: 'nextop',
    component: () => import('../views/M/site/Index.vue')
  },
  {
    path:'/userpage',//nextop个人主页分享
    name: 'userpage',
    component: () => import('../views/M/share/userpage.vue')
  },
  {
    path:'/videoshare',//视频分享
    name: 'videoshare',
    component: () => import('../views/M/share/video.vue')
  },
  {
    path:'/download',//落地页
    name: 'download',
    component: () => import('../views/M/download/Index.vue')
  },
  {
    path:'/code',//二维码
    name: 'code',
    component: () => import('../views/M/download/code.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  // console.log('移动端不缩放')
  const baseSize = 16
  // // 设置 rem 函数
  function setRem() {
    let scale = 0
    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    if (window.orientation == 180 || window.orientation == 0) {
      scale = document.documentElement.clientWidth / 375
    }
    if (window.orientation == 90 || window.orientation == -90) {
      scale = document.documentElement.clientWidth / 667
    }

    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  }
  setRem()
} else {
  router.afterEach(() => {
    // 基准
    const baseSize = 16
    // 设置 rem 函数
    function setRem() {
      // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 1920
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
    }
    setRem()
  })
}
export default router
