import { createStore } from 'vuex'

export default createStore({
  state: {
    headersdata:{ 
      type:'sdk',
      windowheight:'44',
      uid:'5063625'
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
