import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'
import Vant from "vant"
import 'vant/lib/index.css'
// import TCPlayer from 'tcplayer.js';
// import 'tcplayer.js/dist/tcplayer.min.css';
// import VConsole from 'vconsole';


// const vConsole = new VConsole();
// // 结束调试后，可移除掉
// vConsole.destroy();
createApp(App).use(store).use(router).use(Vant).mount('#app')
